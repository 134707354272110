import React from 'react';
import Layout from '#/components/layout';

export default function NotFoundRoute() {
  return (
    <Layout>
      <main>
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </main>
    </Layout>
  );
}
